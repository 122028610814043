import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  PointElement,
  LineElement,
  LineController,
  ScatterController,
  Title,
  Legend,
} from "chart.js";
import "./GraphPanel.css";
import ExposureBarGraph from "../Graph/ExposureBarGraph";
import ExposureHistogram from "../Graph/ExposureHistogram";
import StructDamageBarGraph from "../Graph/StructDamageBarGraph";
import BusinessInterruptBarGraph from "../Graph/BusinessInterruptBarGraph";
import PeerCompareBarGraph from "../Graph/PeerCompareBarGraph";
import graphDescriptions from "../../data/text/graphDescriptions";
ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, PointElement, LineElement, ScatterController, LineController, Title, Legend);

const GraphPanel = (props) => {
  const {
    selectedAssetProperties,
    handleGraphPanelClose,
    selectedTemperatureScenario,
    selectedPortfolio,
    selectedProject,
    peerComparison,
    selectedCompany,
    histBins,
  } = props;
  
  let entityId = "";
  if (selectedAssetProperties) {
    entityId = selectedAssetProperties.entityId ? "(" + selectedAssetProperties.entityId + ")" : "";
  }

  let finWeight = "0.0000"
  try {
    finWeight = selectedAssetProperties.financialExposureWeight.toFixed(4)
  } catch (e) {
    console.log("Cannot process financial exposure weight", selectedAssetProperties.financialExposureWeight)
  }
  console.log(selectedAssetProperties)
  return (
      <div className="graph-panel-container">
        <div className="graph-panel-header">
          {selectedAssetProperties && (
            <div>
              <h5>{selectedAssetProperties.companyName}</h5>
              <h5>{selectedAssetProperties.entityName} {entityId}</h5>
              <h5>{selectedAssetProperties.address}</h5>
              <div style={{"display":"inlineBlock", "marginRight":"10px"}}>
                <h5>Portfolio Weight: {finWeight}</h5>
              </div>
            </div>
          )}
          <div
            className="graph-panel-close-button"
            onClick={() => handleGraphPanelClose()}
          >
            X
          </div>
        </div>
        <div className="grid-container">
          {selectedAssetProperties && (
            <>
              <div className="grid-double-item grid-text">
              <div className="row-title">Financial Impact</div>
                {graphDescriptions["Financial Impact"]}
              </div>
              <div className="grid-item">
                <StructDamageBarGraph
                  selectedAssetProperties={selectedAssetProperties}
                  selectedTemperatureScenario={selectedTemperatureScenario}
                  selectedPortfolio={selectedPortfolio}
                  selectedProject={selectedProject}
                />
                <BusinessInterruptBarGraph
                  selectedAssetProperties={selectedAssetProperties}
                  selectedTemperatureScenario={selectedTemperatureScenario}
                  selectedPortfolio={selectedPortfolio}
                  selectedProject={selectedProject}
                />
              </div>
            </>
          )}
          {selectedAssetProperties && (
            <>
              <div className="grid-double-item grid-text">
              <div className="row-title">Exposure</div>
                {graphDescriptions.Exposure}
              </div>
              <div className="grid-item">
                <ExposureBarGraph
                  selectedAssetProperties={selectedAssetProperties}
                  selectedTemperatureScenario={selectedTemperatureScenario}
                  selectedPortfolio={selectedPortfolio}
                  selectedProject={selectedProject}
                />
                <ExposureHistogram
                  selectedAssetProperties={selectedAssetProperties}
                  selectedTemperatureScenario={selectedTemperatureScenario}
                  selectedPortfolio={selectedPortfolio}
                  selectedProject={selectedProject}
                  histBins={histBins}
                />
              </div>
            </>
          )}
          <div className="grid-double-item grid-text">
          <div className="row-title">Peer Comparison</div>
            {graphDescriptions["Peer Comparison"]}
          </div>
          <div className="grid-item">
            <PeerCompareBarGraph
              type="sd"
              selectedCompany={selectedCompany}
              peerComparison={peerComparison}
            />
            <PeerCompareBarGraph
              type="bi"
              selectedCompany={selectedCompany}
              peerComparison={peerComparison}
            />
          </div>
        </div>
      </div>
    
  );
};

export default GraphPanel;
