import React, {useContext} from "react";
import "./MapFilter.css";
import DropdownMenu from "./DropdownMenu";
import {authContext} from "../../context/authContext";
import {ce_default_url} from "../../context/firebase-config";

const MapFilter = (props) => {
  const {
    portfolios,
    companies,
    selectedProject,
    selectedPortfolio,
    setSelectedPortfolio,
    setSelectedCompany,
    setGraphPanelVisible,
  } = props;

  const user = useContext(authContext);
  const downloadFile = (user, selectedProject, selectedPortfolio) => {
    user && user.getIdTokenResult()
        .then((token) => {
            if (!!token.claims.api_key) {
                fetch(`${ce_default_url}/api/portfolios/${selectedPortfolio}/datasets/financial/export`, {
                    method: 'GET',
                    headers: {
                        'X-SustGlobal-APIKey': token.claims.api_key,
                        'X-SustGlobal-Project': `${selectedProject}`,
                    },
                })
                    .then((response) => response.blob())
                    .then((blob) => {
                        const url = window.URL.createObjectURL(blob);
                        const link = document.createElement('a');
                        link.href = url;
                        link.download = `${selectedPortfolio}-vs.zip`;
                        document.body.appendChild(link);
                        link.click();
                        link.remove()
                        window.URL.revokeObjectURL(url);
                    })
                    .catch((error) => {
                        console.error(`error while fetching from API: ${error}`)
                    });
            } else {
                console.error('api key not available for user')
            }
        })
        .catch((error) => {
            console.error(`error while fetching from API: ${error}`)
        })
  }

  return (
    <div className="filter-panel">
      <div className="filter-container">
        <div className="selector-container">
          <DropdownMenu
            style={{ width: "260px" }}
            options={portfolios}
            onSelect={setSelectedPortfolio}
            label={"Portfolio"}
          />
        </div>
        <div className="selector-container">
          <DropdownMenu
            options={companies}            
            onSelect={setSelectedCompany}
            label={"Company"}
          />
        </div>
        {/* Removed Until Fixed
        <div className="selector-container">
          <button className="summary-button" onClick={() => setGraphPanelVisible(true)}>Summary</button>
        </div>
         */}
        <div className="selector-container">
          <button className="summary-button" onClick={() => downloadFile(user, selectedProject, selectedPortfolio)}>Download</button>
      </div>
      </div>
    </div>
  );
};

export default MapFilter;
